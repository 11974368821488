import merchantsApi from '../../api/merchants';
const state = {
  profile: localStorage.merchant ? JSON.parse(localStorage.merchant) : null,
  vouchers: null,
  offers: null,
  promotions: null,
  categories: null,
  products: null,
  reviews: [],
  paymentMethods: [],
  tableNumbers: null,
  storeClosed: false,
  businessHours: null,
  holidays: null,
  holidayNotes: null,
  cutoffTime: null,
  preparationEst: null,
  deliveryEst: null,
  ondemand: true,
  preOrder: false,
  news: null,
};

const mutations = {
  SET_MERCHANT_PROFILE(state, merchant) {
    localStorage.merchant = JSON.stringify(merchant);
    state.profile = merchant;
  },
  SET_MERCHANT_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers;
  },
  SET_MERCHANT_OFFERS(state, offers) {
    state.offers = offers;
  },
  SET_MERCHANT_PROMOTIONS(state, promotions) {
    state.promotions = promotions;
  },
  SET_MERCHANT_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_MERCHANT_TABLE_NUMBERS(state, tableNumbers) {
    state.tableNumbers = tableNumbers;
  },
  SET_MERCHANT_STORE_CLOSED(state, storeClosed) {
    state.storeClosed = storeClosed;
  },
  SET_MERCHANT_BUSINESS_HOURS(state, businessHours) {
    state.businessHours = businessHours;
  },
  SET_MERCHANT_HOLIDAYS(state, holidays) {
    state.holidays = holidays;
  },
  SET_MERCHANT_HOLIDAY_NOTES(state, holidayNotes) {
    state.holidayNotes = holidayNotes;
  },
  SET_MERCHANT_CUTOFF_TIME(state, cutoffTime) {
    state.cutoffTime = cutoffTime;
  },
  SET_MERCHANT_PREPARATION_ESTIMATION(state, preparationEst) {
    state.preparationEst = preparationEst;
  },
  SET_MERCHANT_DELIVERY_ESTIMATION(state, deliveryEst) {
    state.deliveryEst = deliveryEst;
  },
  SET_MERCHANT_ONDEMAND(state, ondemand) {
    state.ondemand = ondemand;
  },
  SET_MERCHANT_REVIEWS(state, reviews) {
    state.reviews = reviews;
  },
  SET_MERCHANT_PREORDER(state, preOrder) {
    state.preOrder = preOrder;
  },
  SET_MERCHANT_NEWS(state, news) {
    state.news = news;
  },
};

const actions = {
  async getMerchantByHostname(context, hostname) {
    const res = (await merchantsApi.getMerchantByHostname(hostname));
    if (res.status !== 200) {
      return null;
    }
    
    const merchant = res.data;
    context.commit('SET_MERCHANT_PROFILE', merchant);
    context.commit('cart/SET_TAX_RATE', merchant.options.merchant_tax, { root: true });
    context.commit('cart/SET_SERVICE_CHARGE_RATE', merchant.options.merchant_service_charges, { root: true });
    context.commit('cart/SET_PACKAGING_FEE', {
      fee: merchant.options.merchant_packaging_charge,
      incremental: merchant.options.merchant_packaging_increment,
    }, { root: true });
    context.commit('cart/INIT_CART', {}, { root: true });
    return merchant;
  },
  async getMerchantVouchers(context, { merchantId, params }) {
    const vouchers = (await merchantsApi.getMerchantVouchers(context.rootState.auth?.token, merchantId, params)).data;
    context.commit('SET_MERCHANT_VOUCHERS', vouchers);
    return vouchers;
  },
  async getMerchantOffers(context, { merchantId, params }) {
    const offers = (await merchantsApi.getMerchantOffers(merchantId, params)).data;
    context.commit('SET_MERCHANT_OFFERS', offers);
    return offers;
  },
  async getMerchantPromotions(context, { merchantId, params }) {
    const promotions = (await merchantsApi.getMerchantPromotions(merchantId, params)).data;
    context.commit('SET_MERCHANT_PROMOTIONS', promotions);
    return promotions;
  },
  async getMerchantPaymentMethods(context, { merchantId, service }) {
    const res = await merchantsApi.getMerchantPaymentMethods(merchantId, { service });
    if (res?.status !== 200) {
      throw Error(res.data.message);
    }

    const paymentMethods = res.data;
    context.commit('SET_MERCHANT_PAYMENT_METHODS', paymentMethods);
    return paymentMethods;
  },
  async getMerchantTableNumbers(context, { merchantId }) {
    const tableNumbers = (
      await merchantsApi.getMerchantTableNumbers(merchantId, {})
    ).data;
    context.commit('SET_MERCHANT_TABLE_NUMBERS', tableNumbers);
    return tableNumbers;
  },
  async getMerchantBusinessHours(context, { merchantId, service, stateId }) {
    const businessHours = (
      await merchantsApi.getMerchantBusinessHours(merchantId, { service, stateId })
    ).data;

    context.commit('SET_MERCHANT_STORE_CLOSED', businessHours.storeClosed);
    context.commit('SET_MERCHANT_BUSINESS_HOURS', businessHours.businessHours);
    context.commit('SET_MERCHANT_HOLIDAYS', businessHours.holidays);
    context.commit('SET_MERCHANT_HOLIDAY_NOTES', businessHours.holidayNotes);
    context.commit('SET_MERCHANT_CUTOFF_TIME', businessHours.cutoffTime);
    context.commit(
      'SET_MERCHANT_PREPARATION_ESTIMATION',
      businessHours.preparationEst
    );
    context.commit(
      'SET_MERCHANT_DELIVERY_ESTIMATION',
      businessHours.deliveryEst
    );
    context.commit('SET_MERCHANT_ONDEMAND', businessHours.ondemand);
    context.commit('SET_MERCHANT_PREORDER', businessHours.preOrder);

    return businessHours;
  },
  async getMerchantOperatingHours(context, { merchantId }) {
    return (await merchantsApi.getMerchantOperatingHours(merchantId)).data;
  },
  async getMerchantDeliveryHours(context, { merchantId, stateId }) {
    return (await merchantsApi.getMerchantDeliveryHours(merchantId, { stateId })).data;
  },
  async getMerchantHolidays(context, merchantId) {
    const holidays = (
      await merchantsApi.getMerchantHolidays(merchantId)
    ).data?.map(x => new Date(x));
    context.commit('SET_MERCHANT_HOLIDAYS', holidays);
    return holidays;
  },
  async getMerchantReviews(context, merchantId) {
    const reviews = (await merchantsApi.getMerchantReviews(merchantId)).data;
    context.commit('SET_MERCHANT_REVIEWS', reviews);
    return reviews;
  },
  async getMerchantNews(context, merchantId) {
    const news = (await merchantsApi.getMerchantNews(merchantId)).data;
    context.commit('SET_MERCHANT_NEWS', news);
    return news;
  },
  async createMerchantReview(context, { merchantId, data }) {
    const res = await merchantsApi.createMerchantReview(merchantId, context.rootState.auth.token, data);
    if (res?.status !== 200) {
      throw Error(res.data.message);
    }

    return res.data;
  },
};

const getters = {
  // ...
  getDisabledDates(state) {
    const availableDays = state.businessHours?.map(x => x.dayOfWeek);
    return [
      {
        weekdays: [1, 2, 3, 4, 5, 6, 7].filter(
          x => !availableDays?.includes(x)
        ),
      },
      ...(state.holidays ?? []),
    ];
  },
  getServices(state) {
    return state.profile?.service;
  },
  getRating(state) {
    return (
      state.reviews?.reduce((a, c) => a + c.rating, 0.0) / (state.reviews?.length || 1)
    );
  },
  hasDelivery() {
    return !!state.profile?.service.find(service => service.value === 'delivery');
  },
  hasPickup() {
    return !!state.profile?.service.find(service => service.value === 'pickup');
  },
  hasDinein() {
    return !!state.profile?.service.find(service => service.value === 'dinein');
  },
  hasTableBooking(state) {
    return state.profile.options.merchant_master_table_booking === '' && state.profile.options.merchant_table_booking === '';
  },
  hasBranch(state) {
    return state.profile.branch?.length > 0;
  },
  hasLoyalty(state) {
    return state.profile.modules.includes('loyalty');
  },
  isTodayHoliday(state) {
    const dayjs = require('dayjs');
    return state.holidays?.includes(`${dayjs().startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`);
  },
  isRequiredLogin(state) {
    return state.profile?.workflowCustomerLogin === 'required';
  },
  isMenuIncludeTax(state) {
    return state.profile.options.merchant_menu_include_tax === 'yes';
  },
  isMenuItemTakeawayEnabled(state) {
    return state.profile.options.merchant_scan_qr_takeaway === 'yes';
  },
  isMenuItemServeLaterEnabled(state) {
    return state.profile.options.merchant_scan_qr_serve_later === 'yes';
  },
  showOrderNumberOnReceipt(state) {
    return state.profile.options.merchant_order_number_on_receipt === 'yes';
  }
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
